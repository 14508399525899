<template>
  <div>
    <div class="operationControl searchboxfl">
    
      <div class="searchbox" style="margin-top: 10px">
        <div title="课件名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 130px">课件名称:</span>
          <el-input
            v-model="kpointName"
            type="text"
            size="small"
            placeholder="请输入课件名称"
            clearable
          />
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
            width="100px"
          />
          <el-table-column
            label="课程名称"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            fixed
          />
          <el-table-column
            label="课件名称"
            align="left"
            show-overflow-tooltip
            prop="kpointName"
          />
          <el-table-column
                label="视频时长"
                align="center"
                show-overflow-tooltip
                prop="totalTimeSum"
              >
                <template slot-scope="scope">
                  <span>{{ getTime(scope.row.totalTimeSum) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="已观看时长"
                align="center"
                show-overflow-tooltip
                prop="totalWatchTimeSum"
              >
                <template slot-scope="scope">
                  <span>{{ getTime(scope.row.totalWatchTimeSum) }}</span>
                </template>
              </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
 
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "shanxiTrainingSupervisionFirst",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
   kpointName:'', //课件名称
    };
  },
  computed: {},
  created() {
 this.getTableHeight();
    // this.getData()
  },
  methods: {
   
     getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.$route.query.userId) {
        params.userId = this.$route.query.userId;
      }
      if (this.$route.query.projectId) {
        params.projectId = this.$route.query.projectId;
      }
      if (this.$route.query.projectCourseId) {
           let projectCourseIds = []
           projectCourseIds.push(this.$route.query.projectCourseId)
        params.projectCourseIds = projectCourseIds;
      }
      this.doFetch({
        url: "/gov/project/queryPersonalStudyRecordList",
        params,
        pageNum,
      });
    },
    //转换时分秒
     getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    // getData(pageNum, url, name) {
    //   const params = {
    //     pageNum: pageNum,
    //     pageSize: this.pageSize,
    //   };
    //   if (this.auditTimeStort) {
    //     params.auditTimeStort = this.auditTimeStort;
    //   }
    
    //   if (!name) {
    //     let routerName = this.$route.query.active;
    //     if (routerName == "second") {
    //       url = "/run/project/auditPassListPage";
    //     } else if (routerName == "three") {
    //       url = "/run/project/auditRejectListPage";
    //     } else {
    //       url = "/run/project/waitAuditListPage";
    //     }
    //     // this.name = this.$route.query.active || "first";
    //   }

    //   this.doFetch({
    //     url: url,
    //     params,
    //     pageNum,
    //   });
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3 + 1.8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
