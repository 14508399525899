<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox" style="margin-top: 10px">
        <div title="考试类型" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 80px">考试类型:</span>
          <el-select
            v-model="paperUserType"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in paperUseType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div title="是否及格" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 80px">是否及格:</span>
          <el-select
            v-model="paperIsPass"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in PassNot"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            width="60"
            fixed
            :index="indexMethod"
          />

          <el-table-column
            label="课程名称"
            width="200"
            align="left"
            show-overflow-tooltip
            prop="courseName"
          />
          <el-table-column
            label="课件名称"
            align="left"
            show-overflow-tooltip
            prop="catalogName"
            width="200"
          >
            <template slot-scope="scope">{{
              scope.row.catalogName || "--"
            }}</template>
          </el-table-column>
          <el-table-column
            label="试卷名称"
            align="left"
            show-overflow-tooltip
            prop="paperName"
          />
          <el-table-column
            label="考试类型"
            align="center"
            show-overflow-tooltip
            prop="paperUserType"
            width="80"
          >
            <template slot-scope="scope">{{
              $setDictionary("PAPERUSERTYPE", scope.row.paperUserType)
            }}</template>
          </el-table-column>
          <el-table-column
            label="考试时间"
            class="cell2"
            align="left"
            show-overflow-tooltip
            prop="createTime"
            width="150"
          />
          <el-table-column
            label="成绩"
            align="right"
            show-overflow-tooltip
            prop="paperScore"
            width="80"
          />
          <el-table-column
            label="是否及格"
            align="center"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.paperIsComplete ? "是" : "否"
            }}</template>
          </el-table-column>
          <el-table-column
            label="正确率"
            align="right"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.accuracyStr || "0%"
            }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                style="padding: 0px 5px"
                size="mini"
                @click="handledetail(scope.row)"
                >答题详情</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
   <shanxiSixDialog ref="shanxiSixDialog" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import shanxiSixDialog from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiSixDialog.vue";

export default {
  name: "shanxiTrainingSupervisionSix",
  components: {
    Empty,
    PageNum,
    shanxiSixDialog
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      //是否及格
      PassNot: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      paperUseType: [], //考试类型
      paperUserType: "",
      paperIsPass: "",
    };
  },
  computed: {},
  created() {
    this.getPaperUserTypeList();
    this.getTableHeight();
    // this.getData()
  },
  methods: {
    getPaperUserTypeList() {
      const studyList = this.$setDictionary("PAPERUSERTYPEQUERY", "list");
      for (const key in studyList) {
        this.paperUseType.push({
          value: key,
          label: studyList[key],
        });
      }
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = {
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId,
        paperIsComplete: this.paperIsPass,
        paperUserType: this.paperUserType,
      };
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/paperUser/runListPage",
        params,
        pageNum,
      });
    },
    handledetail(row) {
        this.$refs.shanxiSixDialog.showPopUp(row)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3 + 1.8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
