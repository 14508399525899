<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">培训信息监察</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="userInfo">
          <span>班级名称:{{ userInfo.projectName }}</span>
          <span>姓名:{{ userInfo.userName }}</span>
          <span>身份证号:{{ userInfo.idcard }}</span>
          <span>手机号:{{ userInfo.mobile }}</span>
        </div>
        <el-tabs
          v-model="activeName"
          type="card"
          @tab-click="tabsChange"
        >
          <el-tab-pane label="学习记录" name="first">
            <shanxiTrainingSupervisionFirst :name="activeName" ref="first" />
          </el-tab-pane>
          <el-tab-pane label="学习轨迹" name="second">
            <shanxiTrainingSupervisionSecond :name="activeName" ref="second" />
          </el-tab-pane>
          <el-tab-pane label="认证记录" name="three">
            <shanxiTrainingSupervisionThree :name="activeName" ref="three" />
          </el-tab-pane>
          <el-tab-pane label="学时证明" name="four">
            <shanxiTrainingSupervisionFour :name="activeName" ref="four" />
          </el-tab-pane>
          <el-tab-pane label="学员档案" name="five">
            <shanxiTrainingSupervisionFive :name="activeName" ref="five" />
          </el-tab-pane>
          <el-tab-pane label="考试记录" name="six">
            <shanxiTrainingSupervisionSix :name="activeName" ref="six" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import shanxiTrainingSupervisionFirst from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionFirst.vue";
import shanxiTrainingSupervisionSecond from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionSecond.vue";
import shanxiTrainingSupervisionThree from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionThree.vue";
import shanxiTrainingSupervisionFour from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionFour.vue";
import shanxiTrainingSupervisionFive from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionFive.vue";
import shanxiTrainingSupervisionSix from "@/views/shanxiSupervisionEnd/shanxiSupervisonDataStatistics/shanxiTrainingInformationSupervisionTab/shanxiTrainingSupervisionSix.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassReview",
  components: {
    shanxiTrainingSupervisionFirst,
    shanxiTrainingSupervisionSecond,
    shanxiTrainingSupervisionThree,
    shanxiTrainingSupervisionFour,
    shanxiTrainingSupervisionFive,
    shanxiTrainingSupervisionSix,
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      userInfo: {},
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
    this.userInfo = JSON.parse(this.$route.query.userInfo);
  },
  mounted() {},
  methods: {
    // beforeTabLeave(newName) {
    //   this.$router.replace({
    //     path: this.$route.path,
    //     query: { active: newName }
    //   });
    // },
    tabsChange(e) {
      this.activeName = e.name;
      this.name = e.name;
      if (e.name == "second") {
        this.$refs[e.name].getData(-1);
      } else if (e.name == "three") {
        this.$refs[e.name].getData(-1);
      } else if (e.name == "first") {
        this.$refs[e.name].getData(-1);
      } else if (e.name == "four") {
        this.$refs[e.name].getPdf();
      } else if (e.name == "five") {
        this.$refs[e.name].getPdf();
      } else if (e.name == "six") {
        this.$refs[e.name].getData(-1);

      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
//   watch: {
//     $route: function (route) {
//       if (route.query.refresh) {
//         this.$refs[route.query.active].getData(-1);
//       }
//     },
//   },
};
</script>
<style lang="less">
.userInfo {
  span {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}
</style>
