<template>
  <el-dialog :title="rowJson.paperName + '答题详情'" :visible.sync="dialogTableVisible" width="65%">
    <section class="ovy-a" style="height:500px;">
      <div
        class="flexdc"
        style="align-items: flex-start; background: rgba(241, 247, 253, 1)"
      >
        <div style="padding: 20px" class="rowJsontitle">
          <div>
            <img src="@/assets/paperSource.png" alt />
            <span>
              本次成绩：
              <em style="color: #5c6be8; font-size: 20px">{{
                rowJson.paperScore
              }}</em
              >分
            </span>
          </div>
          <div>
            <img src="@/assets/success.png" alt />
            <span>
              正确题数：
              <em style="color: #5c6be8; font-size: 20px">{{
                rowJson.paperRight
              }}</em
              >题
            </span>
          </div>
          <div>
            <img src="@/assets/error.png" alt />
            <span>
              错误题数：
              <em style="color: #5c6be8; font-size: 20px">{{
                rowJson.paperError
              }}</em
              >题
            </span>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <div
            class="bottom searchbox"
            style="padding-top: 0; padding-right: 1rem"
          >
            <h3>试卷题目信息</h3>
          </div>
          <div class="ovy-a flex1" id="list-box">
            <div
              class="listExam"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <div class="list-item df">
                <img
                  src="@/assets/success.png"
                  alt
                  style="width: 16px; height: 16px; margin: 5px"
                  v-if="item.questionIsCorrect == true"
                />
                <img
                  src="@/assets/error.png"
                  alt
                  style="width: 16px; height: 16px; margin: 5px"
                  v-else
                />
                <span
                  class="tixing"
                  :class="{
                    tixing1: item.questionType == 0,
                    tixing2: item.questionType == 1,
                    tixing3: item.questionType == 2,
                    tixing4: item.questionType == 3,
                  }"
                  >{{ $setDictionary("QUESTIONTYPE", item.questionType) }}</span
                >
                <div class="subject flex1">
                  <div class="subject-title">
                    <span>{{ indexMethod(index) }}、</span>
                    <span>{{ item.questionTitle }}</span>
                    <!-- <span class="number">(题目分值：{{ item.questionScore }})</span> -->
                  </div>
                  <div class="option" v-if="item.questionType == 0">
                    <el-radio-group
                      v-model="item.questionMyAnswer"
                      disabled
                      class="radioGroup"
                    >
                      <el-radio label="A">A.{{ item.questionItem1 }}</el-radio>
                      <el-radio label="B">B.{{ item.questionItem2 }}</el-radio>
                      <el-radio label="C" v-if="item.questionItem3"
                        >C.{{ item.questionItem3 }}</el-radio
                      >
                      <el-radio label="D" v-if="item.questionItem4"
                        >D.{{ item.questionItem4 }}</el-radio
                      >
                      <el-radio label="E" v-if="item.questionItem5"
                        >E.{{ item.questionItem5 }}</el-radio
                      >
                      <el-radio label="F" v-if="item.questionItem6"
                        >F.{{ item.questionItem6 }}</el-radio
                      >
                    </el-radio-group>
                  </div>
                  <div class="option" v-if="item.questionType == 1">
                    <el-checkbox-group
                      v-model="item.questionMyAnswerStr"
                      class="radioGroup"
                    >
                      <el-checkbox label="A" disabled
                        >A.{{ item.questionItem1 }}</el-checkbox
                      >
                      <el-checkbox label="B" disabled
                        >B.{{ item.questionItem2 }}</el-checkbox
                      >
                      <el-checkbox label="C" disabled
                        >C.{{ item.questionItem3 }}</el-checkbox
                      >
                      <el-checkbox label="D" disabled v-if="item.questionItem4"
                        >D.{{ item.questionItem4 }}</el-checkbox
                      >
                      <el-checkbox label="E" disabled v-if="item.questionItem5"
                        >E.{{ item.questionItem5 }}</el-checkbox
                      >
                      <el-checkbox label="F" disabled v-if="item.questionItem6"
                        >F.{{ item.questionItem6 }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                  <div class="option" v-if="item.questionType == 2">
                    <el-radio-group v-model="item.questionMyAnswer" disabled>
                      <el-radio label="T">是</el-radio>
                      <el-radio label="F">否</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="option" v-if="item.questionType == 3">
                    <div class="analysis">{{ item.questionMyAnswer }}</div>
                  </div>
                  <div class="analysis">
                    正确答案：{{ item.questionAnswer }}
                  </div>
                  <div style="display: flex">
                    <p>答案解析：</p>
                    <span class="analysis" v-html="item.questionAnalysis">{{
                      item.questionAnalysis
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getDatas" />
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "courseTable",
  components: {
    // Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogTableVisible: false,
      rowJson: {},
    };
  },

  created() {},
  methods: {
    showPopUp(row) {
      this.dialogTableVisible = true;
      this.rowJson = {
        ...row,
      };
      this.init();
    },
    init() {
      this.getDatas();
    },
    getDatas(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperUserId: this.rowJson.paperUserId,
      };
      this.$post("/biz/paperUser/queryPaperUserQuestion", params).then(
        (ret) => {
          const retData = ret.data.list;
          retData.map((item) => {
            item.questionMyAnswerStr = item.questionMyAnswer.split("");
          });
          this.tableData = retData;
          this.apiData = {
            total: ret.data.total || 0,
            size: this.pageSize || 10,
            current: this.pageNum || 1,
          };
        }
      );
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDatas();
    },
    indexMethod(index) {
      return (this.pageNum - 1) * this.pageSize + index + 1;
    },
  
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
  > div {
    display: flex;
    align-items: center;
    font-size: 18px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}
.listExam {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        .radioGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-content: center;
          label {
            margin: 3px 0;
            display: flex;
            justify-content: flex-start;
          }
          .el-radio__label {
            width: 100%;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
</style>

