<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox" style="margin-top: 10px">
        <div title="课件名称" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 130px">课件名称:</span>
          <el-input
            v-model="catalogName"
            type="text"
            size="small"
            placeholder="请输入课件名称"
            clearable
          />
        </div>
        <div title="开始学习时间" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 9rem">开始学习时间:</span>
          <el-date-picker
            v-model="startStudyTime"
            size="small"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div title="结束学习时间" class="searchboxItem ci-full">
          <span class="itemLabel" style="width: 9rem">结束学习时间:</span>
          <el-date-picker
            v-model="endStudyTime"
             size="small"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="df" style="padding-right:30px">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
           <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                minWidth="200"
                prop="courseName"
              />
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                minWidth="200"
                prop="catalogName"
              />
              <el-table-column
                label="视频总时长"
                align="left"
                show-overflow-tooltip
                prop="kpointDuration"
                minWidth="150"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.kpointDuration)}}
              </template>
              </el-table-column>
              <el-table-column
                label="学习视频时长"
                align="left"
                show-overflow-tooltip
                prop="timeInterval"
                minWidth="120"
              >
              <template slot-scope="scope">
                  {{getTime(scope.row.timeInterval)}}
              </template>
              </el-table-column>
              <el-table-column
                label="开始学习时间"
                align="left"
                show-overflow-tooltip
                prop="startTime"
                minWidth="190"
              >
              <template slot-scope="scope">
                  {{scope.row.startTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="结束学习时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                minWidth="180"
              >
              <template slot-scope="scope">
                  {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "shanxiTrainingSupervisionFirst",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      catalogName: "", //课件名称
      startStudyTime: "", //学习开始时间
      endStudyTime: "", //学习结束时间
    };
  },
  computed: {},
  created() {
       this.getTableHeight();
    // this.getData()
  },
  methods: {
   getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.$route.query.userId,
        projectId: this.$route.query.projectId,
        projectCourseId: this.$route.query.projectCourseId,
      };
    //   if (this.ruleForm.projectCourseName) {
    //     params.projectCourseName = this.ruleForm.projectCourseName;
    //   }
      if (this.catalogName) {
        params.catalogName = this.catalogName;
      }
      if (this.startStudyTime) {
        params.startStartDate = this.startStudyTime[0];
        params.startEndDate = this.startStudyTime[1];
      }
      if (this.endStudyTime) {
        params.createStartDate = this.endStudyTime[0];
        params.createEndDate = this.endStudyTime[1];
      }
      this.doFetch({
        url: "/gov/course/study/track/queryStudyTrackList",
        params,
        pageNum,
      });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
     getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3 + 1.8) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
