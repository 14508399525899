<template>
  <div >
    <div class="framePage-scroll">
      <div class="ovy-a" >
        <el-table
          ref="multipleTable"
          :data="tableData"
          size="small"
          tooltip-effect="dark"
          :height="tableHeight"
          :header-cell-style="tableHeader"
          style="padding-right: 10px"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
          />
          <el-table-column
            label="课程名称"
            align="center"
            show-overflow-tooltip
            prop="courseName"
            width="180"
          />
          <el-table-column
            label="课件名称"
            align="center"
            show-overflow-tooltip
            prop="catalogName"
            width="180"
          />
          <el-table-column label="认证照片" align="center" prop="faceInfoBOS">
            <template slot-scope="scope">
              <span
                v-if="scope.row.faceInfoBOS && scope.row.faceInfoBOS.length < 0"
                >--</span
              >
              <div v-else class="checkStyle">
                <div
                  v-for="(item, index) in scope.row.faceInfoBOS"
                  :key="index"
                  class="checkStyleBox"
                >
                  <!-- dealComplete 没有 未标记，   0 是标记了，1是确认补学了(不允许修改)，2是补学完成  -->

                  <el-image
                    style="width:100px;height: 100px;vertical-align: middle;"
                    :src="item.httpApproveFace"
                  >
                  </el-image>
                </div>
              </div>
            </template>
          </el-table-column>

          <Empty slot="empty" />
        </el-table>
      </div>
       
    </div>
         <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "shanxiTrainingSupervisionFirst",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      catalogName: "", //课件名称
      startStudyTime: "", //学习开始时间
      endStudyTime: "", //学习结束时间
      tableData:[]
    };
  },
  computed: {},
  created() {
    this.getData(-1)
  },
  mounted() {
    this.getData(-1);
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        userId: this.$route.query.userId,
        projectId: this.$route.query.projectId,
        projectCourseId:this.$route.query.projectCourseId,
        pageSize: this.pageSize,
        remedyType: 20
      };

      this.doFetch({
        url: "/run/projectCourse/queryUserReviewList",
        params,
        pageNum,
      });
    //   this.getTableHeight();
    },
    handleListDataBack(tableData) {
      this.tableData = tableData;
      this.oneKeyGet(tableData);
    },
    async oneKeyGet(tableData) {
        console.log(tableData);
      for (let i in tableData) {
        let params = {
          projectCourseId: tableData[i].projectCourseId,
          userId: this.$route.query.userId,
          projectId: this.$route.query.projectId,
          kpointId: tableData[i].kpointId
        };
        await this.$post(
          "/run/projectCourse/queryUserReviewImageList",
          params,
          3000,
          false
        ).then((res) => {
          if (res.status == 0) {
            this.$set(this.tableData[i], "faceInfoBOS", [...res.data]);
          }
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      // let tHeight =
      //   window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2  ) * 16;
      // if (opDom) {
      //   tHeight -= 40+ 0.675 * 16 + 1;
      // }
      // if (page) {
      //   tHeight -= 32;
      // }
      // this.tableHeight = tHeight;
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.checkStyle {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end;
  overflow: auto;
}
.checkStyleBox {
  margin: 0 5px;
}

</style>

