<template>
  <div style="height: 100%; display: flex">
    <div class="framePage-scroll">
      <div class="ovy-a">
        <iframe
          style="width: 100%; height: 99%"
          :src="pdfUrl"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
export default {
  name: "shanxiTrainingSupervisionFirst",
  components: {},
  mixins: [List],
  props: ["name"],
  data() {
    return {
      pdfUrl: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    /* 预览pdf */
    getPdf() {
      let parmar = {
        userId: this.$route.query.userId,
        projectId: this.$route.query.projectId,
      };
      this.$post("/cert/downloadTrainDocument", parmar).then((res) => {
        if (res.status == 0) {
          this.pdfUrl = res.data;
        }
      });
    },
  },
  mounted: function () {},
};
</script>


